import _ from "lodash"

class BeforeUnloadManager {
    private items: any = {}

    constructor() {
        window.onbeforeunload = () => {
            if (!this.unloadable()) {
                return "You have unsaved changes on this page. Do you want to leave this page and discard your changes or stay on this page?";
            }
        }
    }

    public unloadable() {
        return _.isEmpty(this.items)
    }

    public begin(item) {
        this.items[item] = true
    }

    public done(item) {
        delete this.items[item]
    }
}

export default new BeforeUnloadManager()
