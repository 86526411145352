function clean(data) {
  const newData = {}
  let ok = false
  for (const k in data) {
    const v = data[k]
    if (typeof v !== "object") {
      if (v) {
        newData[k] = v
        ok = true
      }
    } else if (v instanceof Array) {
      if (v.length) {
        newData[k] = v
        ok = true
      }
    } else {
      const [newV, vOk] = clean(v)
      if (vOk) {
        newData[k] = newV
        ok = true
      }
    }
  }
  return [newData, ok]
}


const compare = (item1, item2) => {
  const [cleanedItem1, okItem1] = clean(item1)
  const [cleanedItem2, okItem2] = clean(item2)
  if (okItem1 !== okItem2) {
    return false
  }

  const cleanedItem1Payload = JSON.stringify(cleanedItem1)
  const cleanedItem2Payload = JSON.stringify(cleanedItem2)
  const result = cleanedItem1Payload === cleanedItem2Payload
  // if (!result) {
  //   console.log("-------------------")
  //   console.log(cleanedItem1Payload)
  //   console.log(cleanedItem2Payload)
  // }
  return result
  // if (!item1 && !item2) {
  //   return true
  // }
  // if (!item1 || !item2) {
  //   return false;
  // }
  // if (!Object(item1) && !Array.isArray(item1) && !Object(item2) && !Array.isArray(item2)) {
  //   return item1 === item2
  // }
  // const combinedObject: any = merge.clone(item1, item2);
  // for (const key in combinedObject) {
  //   if (typeof item1[key] === 'object' && !item2[key]) {
  //     if (checkObjectEmpty(item1[key])) {
  //       continue
  //     }
  //   }
  //   if (typeof item2[key] === 'object' && !item1[key]) {
  //     if (checkObjectEmpty(item2[key])) {
  //       continue
  //     }
  //   }
  //   if (!item1[key] && !item2[key]) {
  //     continue;
  //   }
  //   if (!item1[key] || !item2[key]) {
  //     return false;
  //   }

  //   if (Array.isArray(item1[key]) || typeof item1[key] === "object") {
  //     if (!compare(item1[key], item2[key])) {
  //       return false;
  //     }
  //     continue;
  //   }

  //   if (item1[key] !== item2[key]) {
  //     return false;
  //   }
  // }
  // return true;
}

export default compare
