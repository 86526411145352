













































































































































































































import humanizeString from "humanize-string";
import DataContainer from "../common/DataContainer";
import merge from "../utils/merge";
import defaultComponent from "../utils/defaultComponent";
import _ from "lodash";
import compare from "../utils/compare";
import beforeUnloadManager from "../utils/beforeUnloadManager";
import { debounce } from "debounce";

export default defaultComponent.apply({
  props: ["xData", "xEnableUnloadCheck"],
  data() {
    return {
      data: {},
      sections: {},
      inputs: {},
      inputValids: {},
      inputContainers: {},
      version: 0,
      colLength: 12,
      inputLevelValid: false,
      formLevelValid: false,
      initialData: null,
      decouratedData: {},
      key: "",
      debouncedFormLevelValidate: debounce(() => {
        this.formLevelValidate();
      }, 500),
      inputReceived: false,
      tab: "",
    };
  },
  methods: {
    initialize() {
      this.key = `x-form-${this._uid}`;
      if (this.options.content.colLength) {
        this.colLength = this.options.content.colLength;
      }

      if (_.isEmpty(this.options.content.sections)) {
        this.options.content.sections = {
          default: {},
        };
      }
      if (
        this.options.content.sections.default &&
        !this.options.content.sections.default.fieldNames &&
        !this.options.content.sections.default.fields
      ) {
        const fieldNames = [];
        for (const fieldName in this.options.content.fields) {
          fieldNames.push(fieldName);
        }
        this.options.content.sections.default.fieldNames = fieldNames;
      }
      let i = 0;
      for (const sectionName in this.options.content.sections) {
        const section = this.options.content.sections[sectionName];
        if (i > 0) {
          section.lineSeperated = true;
        }
        i++;
        if (!section.fields && section.fieldNames) {
          section.fields = {};
          for (const fieldName of section.fieldNames) {
            section.fields[fieldName] = this.options.content.fields[fieldName];
          }
          this.options.content.sections[sectionName] = section;
        }
      }

      this.resetData();

      if (this.xData) {
        const self = this;
        this.xData.onUpdated(() => {
          self.resetData();
        });
      }
    },
    resetData() {
      this.decouratedData = {};
      if (this.xData && this.xData.value) {
        this.data.value = merge.clone({}, this.xData.value);
        this.initialData = merge.clone({}, this.xData.value);
      } else {
        this.data.value = {};
        this.initialData = {};
      }
      this.reset();
    },
    validate() {
      return this.$refs.form.validate();
    },
    updateLatestData() {
      this.latestData = merge.clone({}, this.getData());
    },
    reset() {
      this.dirty.value = false;
      this.inputValids = {};
      if (this.xEnableUnloadCheck) {
        beforeUnloadManager.done(this.key);
      }
      this.version++;
      this.updateLatestData();
      for (const sectionName in this.options.content.sections) {
        const section = this.options.content.sections[sectionName];
        for (const fieldName in section.fields) {
          const field = section.fields[fieldName];
          if (!field.attrs) {
            field.attrs = {};
          }
          if (!field.attrs.label) {
            field.attrs.label = humanizeString(fieldName);
          }
          let value;
          const valueGetter = field.ext && field.ext.valueGetter;
          if (valueGetter) {
            value = valueGetter(this.data.value);
          } else {
            let valueFieldName = fieldName;
            if (field.ext && field.ext.formProperty) {
              valueFieldName = field.ext.formProperty;
            }
            value = _.get(this.data.value, valueFieldName);
          }
          if (
            field.ext &&
            field.ext.defaultValue &&
            (value === undefined || value === "")
          ) {
            if (typeof field.ext.defaultValue === "function") {
              value = field.ext.defaultValue(this);
            } else {
              value = field.ext.defaultValue;
            }
          }
          if (value && field.ext && field.ext.onInput) {
            field.ext.onInput(value, this);
          }
          this.inputs[fieldName] = new DataContainer(value);
          this.inputContainers[fieldName] = new DataContainer();
        }
      }
      this.$emit("changed", this.getData());
    },
    getData(ignoreOther?) {
      let data = {};

      if (ignoreOther === undefined) {
        ignoreOther = this.options.content.ignoreOtherFields;
      }

      if (this.initialData) {
        if (!ignoreOther) {
          data = merge.clone(data, this.initialData);
        } else if (this.options.content.extraDataFields) {
          for (const extraDataField of this.options.content.extraDataFields) {
            const value = this.initialData[extraDataField];
            if (value !== undefined) {
              data[extraDataField] = value;
            }
          }
        }
      }

      for (const sectionName in this.options.content.sections) {
        const section = this.options.content.sections[sectionName];
        for (const fieldName in section.fields) {
          if (!this.inputs[fieldName]) {
            continue;
          }
          const field = section.fields[fieldName];
          let valueFieldName = fieldName;
          if (field.ext && field.ext.formProperty) {
            valueFieldName = field.ext.formProperty;
          }
          let value = this.inputs[fieldName].value;
          if (
            value === undefined &&
            field.ext &&
            field.ext.emptyValue !== undefined
          ) {
            value = field.ext.emptyValue;
          }
          _.set(data, valueFieldName, value);
        }
      }
      for (const key in this.decouratedData) {
        _.set(data, key, this.decouratedData[key]);
      }
      return data;
    },
    formLevelValidateAvailable() {
      return (
        this.options.content.rules &&
        !this._.isEmpty(this.options.content.rules)
      );
    },
    async onInputLevelValidChanged() {
      this.inputReceived = true;
      if (!this.inputLevelValid || !this.calculatedValid()) {
        this.model.value = false;
      } else {
        this.model.value = true;
        this.debouncedFormLevelValidate();
      }
      this.$emit("validated", this.model);
    },
    async onInputChanged() {
      if (!this.inputReceived) {
        return;
      }
      this.dirty.value = this.getFormDirty();
      if (this.dirty.value) {
        if (this.xEnableUnloadCheck) {
          beforeUnloadManager.begin(this.key);
        }
      }
      this.debouncedFormLevelValidate();
      if (this.options.content && this.options.content.validationCallback) {
        this.options.content.validationCallback(this, this.getData());
      }
      this.$emit("changed", this.getData());
    },
    getFormDirty() {
      return !compare(this.getData(), this.initialData);
    },
    async formLevelValidate() {
      this.updateLatestData();
      if (!this.inputLevelValid) {
        return;
      }
      if (!this.formLevelValidateAvailable()) {
        return;
      }
      let validateError = null;
      let valid = true;
      for (const key in this.options.content.rules) {
        const rule = this.options.content.rules[key];
        let validator = rule;
        let fields: any = null;
        if (typeof rule !== "function") {
          validator = rule.handler;
          fields = rule.fields;
        }
        if (!fields) {
          fields = Object.keys(this.latestData);
        }

        try {
          this.validating.value = true;
          const result = await validator(this.latestData);
          if (result === false) {
            valid = false;
            break;
          }
        } catch (e) {
          validateError = e;
          valid = false;
          break;
        } finally {
          this.validating.value = false;
        }
      }
      if (validateError) {
        this.error.value = validateError;
        this.formLevelValid = false;
        this.model.value = false;
      } else {
        this.error.value = "";
        this.formLevelValid = valid;
        this.model.value = valid;
      }
    },
    calculatedValid() {
      for (const i in this.inputValids) {
        const inputValid = this.inputValids[i];
        if (!inputValid) {
          return false;
        }
      }
      return true;
    },
  },
  destroyed() {
    if (this.xEnableUnloadCheck) {
      beforeUnloadManager.done(this.key);
    }
  },
  extraOptions: {
    forwardStates: {
      xModel: ["model", false],
      xDirty: ["dirty", false],
      xLoading: ["loading", false],
      xValidating: ["validating", false],
      xError: ["error"],
      xSuccess: ["success"],
      xDisabled: ["disabled", false],
    },
    defaultClass: "form",
  },
});
